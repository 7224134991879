import { Component, OnInit , Inject} from '@angular/core';
import { Utente } from '../utenti/utente.interface';
import { ApiService } from '../../services/api.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-utente',
  templateUrl: './dialog-utente.component.html',
  styleUrls: ['./dialog-utente.component.scss']
})

export class DialogUtenteComponent implements OnInit {

  //@Input() utente:Utente;
  //@Output() get: EventEmitter<any> = new EventEmitter();

  icons = {
    "insert": "plus",
    "update": "save"
  };

  constructor(
    public dialogRef: MatDialogRef<DialogUtenteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Utente,
    private api: ApiService
  ) {}

  ngOnInit(){
  }
/*
  parentGet(): void{
    //aggiorno i dati del componente padre
    this.get.emit();
  }
*/  
  insert(){
    //preparo dati da inserire
    let body = this.data;
    //token
    body['utenti.insert'] = 1 ;
    //id_utente autoincrement
    delete body.id_utente;  
    //chiamo api
    this.api
    .post('php/api/utenti.api.php' , body).subscribe(data => {
      let id_utente = data.id_utente;
      if(id_utente > 0){
        this.dialogRef.close(true);//this.parentGet();
      } else {
        alert('Error');
      }
    }, 
    error => {
      alert("Error");
      console.log(error);
    });
  }

  update(index: number){
    //preparo dati da modificare
    let body = this.data;
    //token
    body['utenti.update'] = 1 ;
    //chiamo api
    this.api.post('php/api/utenti.api.php' , body).subscribe(data => {
      let result = data.result;
      if(result){
        this.dialogRef.close(true);
      } else {
        alert('Error');
      }
    }, 
    error => {
      alert(`Error`);
      console.log(error);
    });
  }

  close(){
    this.dialogRef.close(false);
  }
}
