import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api.service';
import { Registrazione } from './registrazione.interface';

@Component({
  selector: 'app-registrazione',
  templateUrl: './registrazione.component.html',
  styleUrls: ['./registrazione.component.scss']
})

export class RegistrazioneComponent implements OnInit {

  data: Registrazione = {
    id_ufficio: '',
    sesso: ''
  };

  
  cognome = new UntypedFormControl( '' , Validators.required);
  nome = new UntypedFormControl('', Validators.required);
  sesso = new UntypedFormControl('', Validators.required);  
 

  constructor(
    private route: ActivatedRoute ,
    private api: ApiService
  ) { }

  ngOnInit(): void {
    this.data.id_ufficio = this.route.snapshot.paramMap.get('id_ufficio');
  }

  check(){
      console.log(this.data);
  }

  save(){
    console.log('save');
  }

}
