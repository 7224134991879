import { Component, OnInit } from '@angular/core';
import { SessionService } from '../services/session.service';
import { LoginService } from './login.service';
import { Md5 } from 'ts-md5/dist/md5';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  username:string = "";
  password:string = "";
  token:any = null;
  hide:boolean = true;
  
  constructor(
    public session:SessionService ,
    public loginService:LoginService
  ) { }

  ngOnInit():void {
    //se non si è loggati si chiama l'api per ricevere i token necessari alla login
    if(!this.session.is_logged) this.session.get();
    //valorizzo il token con il valore della password
    this.encript(); 
    //this.loginService.session.get(); //controllo is_logged al caricamento della pagina 
  }

  login():void{
    this.loginService.login(this.username , this.token);
  }

  logout():void{
    this.loginService.logout();
  }

  encript():void{
    this.token = Md5.hashAsciiStr(this.password); //md5
  }
  
}