<div class="container-lg">
    <div class="card">
        <div class="table-responsive">
            <div class="div-table hover">
                <div class="thead">
                    <div class="tr active">
                        <div class="th w-10"><input type="text" class="form-control form-control-sm" [(ngModel)]="find.id_utente" placeholder="#"></div>
                        <div class="th w-20"><input type="text" class="form-control form-control-sm" [(ngModel)]="find.cognome" placeholder="cognome" /></div>
                        <div class="th w-20"><input type="text" class="form-control form-control-sm" [(ngModel)]="find.nome" placeholder="Nome" /></div>
                        <div class="th w-15"><input type="text" class="form-control form-control-sm" [(ngModel)]="find.username" placeholder="Username" /></div>
                        <div class="th w-20"><input type="text" class="form-control form-control-sm" [(ngModel)]="find.email" placeholder="E-mail" /></div>
                        <div class="th w-10"><!--select permesso--></div>
                        <div class="th w-5">
                            <button class="btn btn-outline-secondary btn-sm" (click)="get()" matTooltip="Cerca">
                                <span class="fas fa-search"></span>
                            </button>
                        </div>
                    </div><!--filtro-->
                    <div class="tr active">
                        <div class="th w-10">#</div>
                        <div class="th w-20">COGNOME</div>
                        <div class="th w-20">NOME</div>
                        <div class="th w-15">USERNAME</div>
                        <div class="th w-20">E-MAIL</div>
                        <div class="th w-10">PERMESSO</div>
                        <div class="th w-5">
                            <button class="btn btn-outline-primary btn-sm" (click)="add()" matTooltip="Nuovo utente">
                                <span class="fas fa-plus"></span>
                            </button>
                        </div>
                    </div><!--head-->
                </div><!--thead-->
                <div class="tbody">
                    <div class="tr pointer" *ngFor="let x of data; let i = index" (click)="edit(i)">
                        <div class="td w-10">{{x.id_utente}}</div>
                        <div class="td w-20">{{x.cognome}}</div>
                        <div class="td w-20">{{x.nome}}</div>
                        <div class="td w-15">{{x.username}}</div>
                        <div class="td w-20"><div class="ellipsis">{{x.email}}</div></div>
                        <div class="td w-10"><div class="ellipsis">{{x.permesso}}</div></div>
                        <div class="td w-5"><!--search--></div>
                    </div>
                </div><!--tbody-->
                <div class="tfoot">
                    <div class="tr">
                        <div class="td w-100" align="center">
                            <app-paginator [rc]="rc" [(limit)]="find.limit" [(offset)]="find.offset" (get)="get()"></app-paginator>
                        </div>
                    </div>
                </div><!--tfoot-->
            </div><!--div-table-->
        </div>
    </div>
</div>