<h2 mat-dialog-title *ngIf="data.id_utente > 0">Dettaglio Utente #{{data.id_utente}}</h2>
<h2 mat-dialog-title *ngIf="data.id_utente == ''">Inserimento nuovo Utente</h2>
<mat-dialog-content>
    <div class="row mb-3">
        <div class="col-auto">
            <label class="form-label">Cognome</label>
            <input [(ngModel)]="data.cognome"
                type="input" 
                class="form-control" 
                cdkFocusInitial>
        </div>
        <div class="col-auto">          
            <label class="form-label">Nome</label>
            <input [(ngModel)]="data.nome"
                type="input" 
                class="form-control">
        </div>
    </div><!--row-->
    <div class="row mb-3">
        <div class="col-auto">
            <label class="form-label">Username</label>
            <input [(ngModel)]="data.username"
            type="input" 
            class="form-control">
        </div>
        <div class="col-auto"> 
            <label class="form-label">E-mail</label>
            <input [(ngModel)]="data.email"
            type="input" 
            class="form-control">
        </div>
    </div><!--row-->
</mat-dialog-content>

    <div class="d-f jc-sb">
        <button type="button" class="btn btn-outline-secondary btn-sm" 
            (click)="close()" 
            matTooltip="Annulla">
            <span class="fas fa-times"></span>
        </button>
        <button type="button" class="btn btn-outline-primary btn-sm" 
            (click)="update()" 
            matTooltip="Conferma" 
            *ngIf="data.id_utente > 0">
            <span class="fas fa-{{icons.update}}"></span>
        </button>
        <button type="button" class="btn btn-outline-primary btn-sm" 
            (click)="insert()" 
            matTooltip="Conferma" 
            *ngIf="data.id_utente == ''">
            <span class="fas fa-{{icons.insert}}"></span>
        </button>
    </div>
