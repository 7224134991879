<div class="col-4 justify-content-around">
    <div class="input-group input-group-sm">
        <button type="button" class="btn btn-outline-secondary" [disabled]="limit == 0">
            <span class="fas fas fa-chevron-left"></span>
        </button>
        <select class="form-select" [(ngModel)]="offset" (change)="parentGet()">
            <option value="15">15</option>
            <option value="50">50</option>
            <option value="100">100</option>
        </select>
        <span class="input-group-text">{{rc}}</span>
        <button type="button" class="btn btn-outline-secondary" [disabled]="(limit + offset) >= rc">
            <span class="fas fas fa-chevron-right"></span>
        </button>
    </div>
</div>
