import { Component, OnInit , Input , Output , EventEmitter} from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
  
  @Input() rc:number;
  @Input() limit:number;
  @Input() offset:number;
  @Output() offsetChange = new EventEmitter();
  @Output() limitChange = new EventEmitter();
  @Output() get: EventEmitter<any> = new EventEmitter();



  constructor() { }

  ngOnInit(): void {
    
  }

  pageNext(){
    //calcolo nuovo limit
    this.limit = this.limit + this.offset;
    //esci se limit + offset superano o uguagliano il record count
    if((this.limit + +this.offset) >= this.rc) return;
    
  }

  pagePrevious(){
    //calcolo nuovo limit
    this.limit = this.limit - this.offset;
    //esci se limit minore o uguale a zero
    if(this.limit < 0) this.limit = 0;
    //aggiorno i dati del componente padre
    this.get.emit();
  }

  parentGet(): void{
    //aggiorna il limit
    this.limitChange.emit(this.limit);
    //aggiorno l'offset
    this.offsetChange.emit(this.offset);
    //aggiorno i dati del componente padre
    this.get.emit();
  }

}
