import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-immobile',
  templateUrl: './dialog-immobile.component.html',
  styleUrls: ['./dialog-immobile.component.scss']
})
export class DialogImmobileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
