import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../services/session.service';
import { ApiService } from '../../services/api.service';
import { Utente } from './utente.interface';
import { MatDialog } from '@angular/material/dialog';
import { DialogUtenteComponent } from '../dialog-utente/dialog-utente.component';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-utenti',
  templateUrl: './utenti.component.html',
  styleUrls: ['./utenti.component.scss']
})

export class UtentiComponent implements OnInit {
  
  data:Utente[] = [];
  rc:number = 0;

  utente:Utente = {
    "id_utente" : "" ,
    "cognome" : "",
    "nome" : "" ,
    "username": "" ,
    "email" : "",
  };

  find:Utente = {
    "id_utente": this.cookieService.get('utenti.id_utente') || "",
    "cognome": this.cookieService.get('utenti.cognome') || "",
    "nome": this.cookieService.get('utenti.nome') || "",
    "username": this.cookieService.get('utenti.username') || "",
    "email": this.cookieService.get('utenti.email') || "",
    "limit": this.cookieService.get('utenti.limit') || 0 ,
    "offset": this.cookieService.get('utenti.offset') || 15
  };

  //spostare nel servizio api
  setCookies(){
    for (const [key, value] of Object.entries(this.find)) {
      this.cookieService.set(`utenti.${key}` , value );
    }
  }

  constructor(
    private api: ApiService,
    public session: SessionService,
    public dialog: MatDialog,
    private cookieService: CookieService
  ) {
  }


  ngOnInit():void {
    this.get();
  }

  get():void{
    //memorizzo i cookies
    this.setCookies();
    //preparo dati da inviare
    let body = this.find;
    body['utenti.get'] = 1;
    //post
    this.api.post('php/api/utenti.api.php' , body)
    .subscribe(result => {
        this.data = result.data.data;
        this.rc = result.data.rc
      }, 
      error => {
        console.log(error);
      }
    );
  }

  add(){
    //prepara dialog per aggiunta nuovo utente
    this.utente = {"id_utente" : ''};
    this.openDialog(0);
  }

  edit(index:number){
    //prepara dialog per modifica utente esistente
    this.utente = Object.assign({}, this.data[index]);
    this.openDialog(index);
  }


  openDialog(index:number){
    //set dati 
    let dialogRef = this.dialog.open(DialogUtenteComponent, {"data": this.utente});
    //callback
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result){
          this.get();
      }
    });
    
  }

}
