<div class="container-fluid">
  <div class="row" id="row-login">
    <div class="col-auto">
      <form #form="ngForm">
        <div class="card">
        
          <div class="card-header" *ngIf="!session.is_logged">
            <i class="fas fa-globe"></i> Rogledi.it
          </div>

          <div class="card-body" *ngIf="!session.is_logged">
            <div class="col-auto mb-3">
              <div class="input-group input-group-sm">
                <span class="input-group-text">
                  <i class="fas fa-user"></i>
                </span>
                <input [(ngModel)]="username" 
                  class="form-control" 
                  name="username" 
                  #usernameControl="ngModel"
                  placeholder="Username" 
                  minlength="8" required />        
              </div>
              <small class="c-red" *ngIf="(usernameControl.dirty || usernameControl.touched) && usernameControl.hasError('minlength')">Lunghezza minima 6 caratteri</small>
              <small class="c-red" *ngIf="(usernameControl.dirty || usernameControl.touched) && usernameControl.hasError('required')">Campo obbligatorio</small>
            </div>
            
            <div class="col-auto mb-3">
              <div class="input-group input-group-sm">
                <span class="input-group-text">
                  <i class="fas fa-lock"></i>
                </span>
                <input [(ngModel)]="password" 
                  class="form-control" 
                  [type]="hide ? 'password' : 'text'" 
                  name="password" 
                  (keyup)="encript()"
                  placeholder="Password" 
                  #passwordControl="ngModel"
                  minlength="8" required>
                
                  <button tabindex="-1" 
                    type="button" 
                    class="btn btn-outline-secondary"
                    (click)="hide = !hide">
                    <i class="fas fa-{{hide ? 'eye-slash' : 'eye'}}"></i>
                  </button><!--mostra password--->
              
                </div>
                <small class="c-red" *ngIf="(passwordControl.dirty || passwordControl.touched) && passwordControl.hasError('minlength')">Lunghezza minima 8 caratteri</small>
                <small class="c-red" *ngIf="(passwordControl.dirty || passwordControl.touched) && passwordControl.hasError('required')">Campo obbligatorio</small>
            </div>
          </div><!--card-body-->
            
          <div class="card-footer ta-c" *ngIf="!session.is_logged">
            <button type="button" 
              matTooltip="Accedi"
              class="btn btn-outline-primary btn-sm" 
              (click)="login()" 
              [disabled]="form.invalid">
              <span class="fas fa-sign-in-alt"></span>
            </button><!--accedi-->
          </div>

          <div class="card-body ta-c" *ngIf="session.is_logged">
            <button type="button" 
              matTooltip="Esci"
              class="btn btn-outline-primary btn-sm" 
              (click)="logout()">
              <i class="fas fa-sign-out-alt"></i> Esci
            </button><!--esci-->
          </div><!--card-body-->
        </div>
      </form>
    </div><!--col-auto-->
  </div><!--row-->
</div>
