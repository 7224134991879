import { Component, Input, OnInit, Output } from '@angular/core';
import { SessionService } from '../services/session.service';
import { LoginService } from '../login/login.service';
import { ConfigService } from '../services/config.service';
import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {

  @Input() isScreenSmall: boolean;
  @Output() toggleSidenav = new EventEmitter();


  

  

  constructor(
    public session:SessionService ,
    public login:LoginService ,
    public config:ConfigService ,
    public router:Router
  ) { }




  ngOnInit(): void {
  }
}
