<div style="padding:25px" align="center">
  <img src="../../assets/images/iulm.png" align="middle" />
</div>

<form #myForm="ngForm">
  <mat-card class="">

    <div align="center">
      <b><u>DICHIARAZIONE</u></b>
    </div>

    <br />

    <section>
      <div class="form-group input-group-sm col-auto">
        Il/La sottoscritto/a
      </div>
    </section>

    <section>
      <mat-form-field appearance="fill">
        <mat-label>Cognome</mat-label>
        <input [(ngModel)]="data.cognome" 
          ngModel 
          type="text"
          name="congome" 
          matInput 
          [formControl]="cognome" 
          (keyup)="check()" 
          maxlength="50"
          required />
        <!--mat-hint align="end">Here's the dropdown arrow ^</mat-hint-->
        <mat-error *ngIf="cognome.hasError('required')">Campo obbligatorio</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Nome</mat-label>
        <input [(ngModel)]="data.nome" matInput name="nome" [formControl]="nome" (keyup)="check()" required
          maxlength="50" />
        <!--mat-hint align="end">Here's the dropdown arrow ^</mat-hint-->
        <mat-error *ngIf="nome.hasError('required')">Campo obbligatorio</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-select [(ngModel)]="data.sesso" name="sesso" [formControl]="sesso" (selectionChange)="check()" required>
          <mat-option value="">...</mat-option>
          <mat-option value="F">F</mat-option>
          <mat-option value="M">M</mat-option>
        </mat-select>
        <mat-error *ngIf="sesso.hasError('required')">Campo obbligatorio</mat-error>
      </mat-form-field>
    </section>

    <section>
      <button type="submit" (click)="save()" mat-stroked-button color="primary" [disabled]="myForm.invalid">
        <span class="fas fa-save"></span>
      </button>
      valid: {{myForm.valid}}
      invalid: {{myForm.invalid}}
    </section>
  </mat-card>
</form>
