<ul class="sidenav">
    <li>
        <a class="pointer">
            <span class="fas fa-chevron-left fs-25"></span>
        </a>
    </li>
    <li class="active">
        <a href="/1/dashboard" *ngIf="session.id_permesso == 1" routerLink="/1/dashboard" routerLinkActive="activebutton">
            <span class="fas fa-dot-circle fs-25 mr-3 c6"></span> DASHBOARD
        </a>
    </li>
    <li>
        <a href="/1/utenti" *ngIf="session.id_permesso == 1" routerLink="/1/utenti" routerLinkActive="activebutton"> 
            <i class="fas fa-users fs-25 mr-3"></i> UTENTI
        </a>
    </li>
    <li>
        <a href="/1/immobili" *ngIf="session.id_permesso == 1" routerLink="/1/immobili" routerLinkActive="activebutton"> 
            <i class="fas fa-building fs-25 mr-3"></i> IMMOBILI
        </a>
    </li>
    <li>
        <a href="/1/configs" *ngIf="session.id_permesso == 1" routerLink="/1/configs" routerLinkActive="activebutton"> 
            <i class="fas fa-cog fs-25 mr-3"></i> CONFIGS
        </a>
    </li>
    <li>
        <a href="/1/test" *ngIf="session.id_permesso == 1" routerLink="/1/test" routerLinkActive="activebutton"> 
            <i class="fas fa-flask fs-25 mr-3"></i> TEST
        </a>
    </li>
    <li>
        <a href="/1/login" *ngIf="session.is_logged" routerLink="/login" routerLinkActive="activebutton">
            <span class="fas fa-power-off fs-25 mr-3"></span> ESCI
        </a>
    </li>
</ul>