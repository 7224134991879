<div class="container-lg">
  <div class="search">
    <div class="row">
      <div class="col-auto">
        <button type="button" class="btn btn-outline-light text-dark btn-sm" (click)="get()">
          <span class="fas fa-search"></span>
        </button>
      </div>
      <div class="col-auto">
        <input type="text" class="form-control form-control-sm" [(ngModel)]="find.comune" placeholder="Comune" />
      </div>
      <div class="col-auto">
        <input type="text" class="form-control form-control-sm" [(ngModel)]="find.indirizzo" placeholder="Indirizzo" />
      </div>
    </div>
  </div>

  <div class="table-responsive mat-elevation-z0">
    <mat-accordion multi="true">
      <mat-expansion-panel *ngFor="let x of data; let i = index">
        <mat-expansion-panel-header (click)="get_pratiche(i)">
          <mat-panel-title>
            <span class="fas fa-map-marker-alt mr-3"></span> {{x.comune}} ({{x.prov}})
          </mat-panel-title>
          <mat-panel-description>
            {{x.indirizzo}} {{x.civico}}
            
              <mat-icon class="fas fa-pencil-alt"></mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <table class="table table-borderless table-hover pointer">
            <thead class="table-light">
              <tr>
                <td>#</td>
                <td>Attività</td>
                <td>Provider</td>
                <td><span class="fas fa-cog"></span></td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let y of x.pratiche; let i = index">
                <td><span class="badge bg-light text-dark">{{y.id_pratica}}</span></td>
                <td>{{y.attivita}}</td>
                <td>{{y.provider}}</td>
                <td><!--cog--></td>
              </tr>
            </tbody>
            <tfoot class="table-light">
              <tr>
                <td><!--id_pratica--></td>
                <td>
                  <select class="form-select form-select-sm" [(ngModel)]="new.id_attivita">
                      <option *ngFor="let x of attivita" value="{{x.id}}">{{x.value}}</option>
                  </select>
                </td>
                <td>
                  <select class="form-select form-select-sm" [(ngModel)]="new.id_provider">
                    <option *ngFor="let x of providers" value="{{x.id}}">{{x.value}}</option>
                </select>
                </td>  
                <td>
                  <button type="button" 
                    class="btn btn-outline-success btn-sm" 
                    [disabled]="new.id_attivita==0 || new.id_provider==0"
                    (click)="insert_pratica(i)">
                    <span class="fas fa-plus"></span>
                  </button>
                </td>
              </tr>
            </tfoot>
          </table>
          <!--app-pratiche #pratiche [id_immobile]="x.id_immobile"></app-pratiche-->
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>