
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
@Injectable({
  providedIn: 'root'
})
export class SessionService {

  is_logged:boolean = false;
  id_permesso:number;
  username:string;
  id_utente:number;
  csrf:any = null;
  
  constructor(private api:ApiService) { }

  async get(){
    let body = {"session.get": 1};

    await this.api
    .post('php/api/session.api.php' , body).toPromise()
    .then(data => {
      this.is_logged = data.is_logged;
      this.id_permesso = data.id_permesso;
      this.id_utente = data.id_utente;
      this.username = data.username;
      this.csrf = data.csrf;
    }, 
      error => {
        console.log(error);
      }
    );

  }
}
