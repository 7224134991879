import { Component, OnInit, AfterViewInit , ViewChildren , QueryList } from '@angular/core';
import { Immobile } from './immobile.interface'
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from '../../services/api.service';
import { SessionService } from '../../services/session.service';
//import { PraticheService } from '../../services/pratiche.service'
//import { PraticheComponent } from '../pratiche/pratiche.component';
//import { Pratica } from '../pratiche/pratica.interface'


@Component({
  selector: 'app-immobili',
  templateUrl: './immobili.component.html',
  styleUrls: ['./immobili.component.scss']
})

export class ImmobiliComponent implements OnInit{
  
  //@ViewChildren('pratiche') pratiche:QueryList<PraticheComponent>;

  immobile: Immobile = {id_immobile: ''};
  data: Immobile[] = [];
  rc: number = 0;
  new = {id_attivita: 0 , id_provider: 0};

  attivita = [
    {id: 0 , value: "..."},
    {id: 1 , value: "Valutazione"}
  ];
  
  providers = [
    {id: 0 , value: "..."},
    {id: 1 , value: "BS360"}
  ];

  find: Immobile = {
    id_immobile: this.cookieService.get('immobile.id_utente') || "",
    indirizzo: this.cookieService.get('immobile.indirizzo') || "",
    comune: this.cookieService.get('immobile.comune') || "",
    civico: this.cookieService.get('immobile.civico') || "",
    cap: this.cookieService.get('immobile.cap') || "",
    prov: this.cookieService.get('immobile.prov') || '' ,
    interno: this.cookieService.get('immobile.interno') || '',
    scala: this.cookieService.get('immobile.scala') ||'',
    quartiere: this.cookieService.get('immobile.quartiere') ||'',
    frazione: this.cookieService.get('immobile.frazione') ||'',
    localita: this.cookieService.get('immobile.localita') ||'',
    limit: this.cookieService.get('immobile.limit') || 0 ,
    offset: this.cookieService.get('imombile.offset') || 15
  };

  //spostare nel servizio api
  setCookies(){
    for (const [key, value] of Object.entries(this.find)) {
      this.cookieService.set(`immobile.${key}` , value );
    }
  }

  constructor(
    private api: ApiService,
    public session: SessionService,
    private cookieService: CookieService,
    //public pratiche: PraticheService
  ) { }

  ngOnInit(): void {
    this.get();
  }
//creare e spostare nel servizio
  get():void{
    //memorizzo i cookies
    this.setCookies();
    //preparo dati da inviare
    let body = this.find;
    body['immobili.get'] = 1;
    
    this.api.post('php/api/immobili.api.php' , body)
    .subscribe(result => {
        this.data = result.data.data;
        this.rc = result.data.rc
      }, 
      (error: any) => {
        console.log(`Error: ${error}`);
      }
    );
  }
  
  
  get_pratiche(i: number, k:boolean = true){
    
    
    if(k){
      //controllo se le pratiche sono già state recuperate
      if(this.data[i].pratiche) return false
    }
    
    //preparo dati da inviare
    let body = {
      "id_immobile": this.data[i].id_immobile,
      "pratiche.get": 1
    };

    //post
    this.api.post('php/api/pratiche.api.php' , body)
    .subscribe(result => {
        this.data[i].pratiche = result.data.data;
      }, 
      (error: any) => {
        console.log(`Error: ${error}`);
      }
    );
  }

  insert_pratica(i: number){

    let body = {
      "id_immobile": this.data[i].id_immobile,
      "id_attivita": this.new.id_attivita,
      "id_provider": this.new.id_provider,
      "pratiche.insert": 1
    };

    this.api.post('php/api/pratiche.api.php' , body)
    .subscribe(result => {
        let id_pratica = result.id_pratica;

        if(id_pratica > 0){
          this.get_pratiche(i, false);

          this.new = {id_attivita: 0 , id_provider: 0};
        } else {
          alert(`Error: ${id_pratica}`);
        }
      }, 
      (error: any) => {
        console.log(`Error: ${error}`);
      });
  }
  
}
