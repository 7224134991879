<app-navbar (toggleSidenav)="toggle()" [isScreenSmall]="isScreenSmall" *ngIf="session.is_logged"></app-navbar>
<div class="row">
    <div class="col-left col-1 col-sm-2">
        <app-sidenav></app-sidenav>
    </div>
    <div class="col-container col-11 col-sm-10">
        <router-outlet></router-outlet>
    </div>
</div>
<!--mat-drawer-container class="app-sidenav-container">
    <mat-drawer #drawer *ngIf="session.is_logged"       
        class="app-sidenav mat-elevation-z0"  
        [mode]="isScreenSmall ? 'over' : 'side'" 
        [opened]="!isScreenSmall">
        <app-sidenav></app-sidenav>
    </mat-drawer>
    <mat-drawer-content class="app-sidenav-content">
        <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container-->