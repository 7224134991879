import { Component } from '@angular/core';
import { SessionService } from '../services/session.service';


@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent {

  constructor(
    public session:SessionService
  ) { }

}
