import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RegistrazioneComponent } from './registrazione/registrazione.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { DialogUtenteComponent } from './1/dialog-utente/dialog-utente.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { CookieService } from 'ngx-cookie-service';
import { RouteGuardService } from './services/route-guard.service';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MaterialModule } from './shared/material.module';
import { DashboardComponent } from './1/dashboard/dashboard.component';
import { UtentiComponent } from './1/utenti/utenti.component';
import { ConfigsComponent } from './1/configs/configs.component';
import { TestComponent } from './1/test/test.component';
import { ImmobiliComponent } from './1/immobili/immobili.component';
import { DialogImmobileComponent } from './1/dialog-immobile/dialog-immobile.component';
import { PraticheComponent } from './1/pratiche/pratiche.component';
import { CommonModule } from '@angular/common';
@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    providers: [
        CookieService,
        RouteGuardService
    ],
    declarations: [
        AppComponent,
        DashboardComponent,
        PageNotFoundComponent,
        RegistrazioneComponent,
        LoginComponent,
        NavbarComponent,
        SidenavComponent,
        UtentiComponent,
        DialogUtenteComponent,
        PaginatorComponent,
        ConfigsComponent,
        TestComponent,
        SidebarComponent,
        ImmobiliComponent,
        DialogImmobileComponent,
        PraticheComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }