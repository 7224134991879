import { Component, Input, OnInit } from '@angular/core';
import { Pratica } from './pratica.interface';
//import { PraticheService } from '../../services/pratiche.service';
import { ApiService } from '../../services/api.service'

@Component({
  selector: 'app-pratiche',
  templateUrl: './pratiche.component.html',
  styleUrls: ['./pratiche.component.scss']
})
export class PraticheComponent implements OnInit {

  @Input() id_immobile:number ;

  data: Pratica[] = [];
  new: Pratica = {};
  
  attivita = [
    {id: 0 , value: "..."},
    {id: 1 , value: "Valutazione"}
  ];
  
  providers = [
    {id: 0 , value: "..."},
    {id: 1 , value: "BS360"}
  ];

  constructor(
    //public pratiche: PraticheService
    public api: ApiService
  ) { }

  ngOnInit(): void {
    
  }

  insert(){

  }
  
  get(){
    //preparo dati da inviare
    let body = {
      "id_immobile": this.id_immobile,
      "pratiche.get": 1
    };

    //post
    this.api.post('php/api/pratiche.api.php' , body)
    .subscribe(result => {
        this.data = result.data.data;
      }, 
      (error: any) => {
        console.log(`Error: ${error}`);
      }
    );
  }
}
