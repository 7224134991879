import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { SessionService } from './session.service';
@Injectable({
  providedIn: 'root'
})
export class RouteGuardService implements CanActivate{

  constructor(
    private session: SessionService,
    private router: Router
  ) { }

  async canActivate(route: ActivatedRouteSnapshot , state: RouterStateSnapshot){
    //controllo se l'utente non è loggato 
    if(!this.session.is_logged) await this.session.get();
    //elenco permessi in base ai quali controllare se visualizzare la pagina
    let permessi:any;  
    //variabile che impostata a false rimanderà alla pagina di login         
    let result:any = false;     
    //elenco permessi relativi all'url selezionato
    switch(state.url)
    {
      case "/1/dashboard": permessi = [1];  break;
      case "/1/utenti": permessi = [1]; break;
      case "/1/immobili": permessi = [1]; break;
      case "/1/configs": permessi = [1]; break;
      case "/1/test": permessi = [1]; break;
    }
    //controllo se i permessi combaciano con quello in sessione
    result = permessi.includes(this.session.id_permesso);
    //se non combaciano vai alla login
    if(!result) this.router.navigate(['login']);
    return result;
  }
}
