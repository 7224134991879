<div class="container-lg">

    <h3>Home</h3>
    
    <mat-card>
        <mat-card-title>Statistiche</mat-card-title>
        <mat-card-content>
            This is the card Content
        </mat-card-content>
        <mat-card-actions align="end">
            <button mat-flat-button>Cerca</button>
        </mat-card-actions>
    </mat-card>
</div>
    

