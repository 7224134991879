import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private domain:string ;
  private protocol:string;
  private httpOptions:object = {
    
  headers: new HttpHeaders({ 
      'Content-Type': 'application/json'
    }),
    withCredentials: true
  };

  constructor(private http: HttpClient) { }

  post(uri:string , body:object = {} ):Observable<any>{
    //call API 
    this.init();
    let endpoint = `${this.protocol}://${this.domain}/${uri}`;
    return this.http.post<any[]>(endpoint , body , this.httpOptions);
  }

  init():void{
    let hostname = location.hostname;
    
    if(hostname === 'localhost') {
      this.protocol = 'http';
      this.domain = 'rogledi.local';
      this.domain = 'localhost/rogledi';
    } else {
      this.protocol = 'https';
      this.domain = hostname;
    }
  }
/*
  async getIdPermesso(uri:string , body:object = {} ){
    //call API 
    let httpOptions:object = {
      headers: new HttpHeaders({ 
          'Content-Type': 'application/json'
        }),
        withCredentials: true
    };

    let result:any = false;

    this.init();

    let url = `${this.protocol}://${this.domain}/${uri}`;

    result = this.http.post<any[]>(url , body , this.httpOptions);

    return result;
  }
*/
}
