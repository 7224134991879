import { Component } from '@angular/core';
import { SessionService } from '../../services/session.service';



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [ './dashboard.component.scss' ]
})

export class DashboardComponent {

  constructor(
    public session: SessionService
  ) { }

 
}