//import { convertUpdateArguments } from '@angular/compiler/src/compiler_util/expression_converter';
import { Component, OnInit } from '@angular/core';
import * as p5 from 'p5';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {
  p5 : p5;
  
  constructor() { }

  ngOnInit(): void {
    this.asteroids();
  }

  private createCanvas() {
    this.p5 = new p5(this.asteroids);
  }
  private asteroids_p5js(p: any) {
    const width = window.innerWidth;
    const height = window.innerHeight;

    function setup(){
      p.createCanvas();
    }

    function draw(){

    }

    function Ship(){
      this.pos = 2
    }
  }

  asteroids(){
    const FPS = 30; //frames per seconds
    
    const SHIP_SIZE = 30;
    
    var canv: HTMLCanvasElement  = <HTMLCanvasElement> document.getElementById("canvas");
      canv.width = 700;
      canv.height = 500;
    
    var ctx: CanvasRenderingContext2D = canv.getContext('2d');
    
    var ship = {
      x: canv.width / 2,
      y: canv.height / 2,
      r: SHIP_SIZE / 2,
      a: 90 / 180 * Math.PI //convert to radiant
    };

    function update(){
      //draw space;
      ctx.fillStyle = "black";
      ctx.fillRect(0 , 0 , canv.width , canv.height);

      //draw ship
      ctx.strokeStyle = "white";
      ctx.lineWidth = SHIP_SIZE / 20;
      ctx.beginPath();
      ctx.moveTo(
        ship.x + ship.r * Math.cos(ship.a),
        ship.y - ship.r * Math.sin(ship.a)
      );
      ctx.lineTo( //left
        ship.x - ship.r * (Math.cos(ship.a)) + Math.sin(ship.a) ,
        ship.y + ship.r * (Math.sin(ship.a)) - Math.cos(ship.a)
      );
      ctx.lineTo( //right
        ship.x - ship.r * (Math.cos(ship.a)) - Math.sin(ship.a) ,
        ship.y + ship.r * (Math.sin(ship.a)) + Math.cos(ship.a)
      );
      ctx.closePath();
      ctx.stroke();
      //rotate ship

      //move the ship

      //center dot
      ctx.fillStyle = "red";

    }
    
    setInterval(update ,1000 / FPS);
  }

  
}
