import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { SessionService } from '../services/session.service';

import { Router } from '@angular/router';
import { ConditionalExpr } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  
  constructor(
    private session: SessionService,
    private api: ApiService ,
    private router: Router
  ) { }

  login(username: string , token: string): void{
    
    let body = {
      "csrf" : this.session.csrf,
      "username" : username,
      "token" : token,
      "log.in" : 1
    };

    this.api
    .post('php/api/login.api.php' , body)
    .subscribe(data => {
        //aggiorno con i dati di sessione
        this.session.get();
        //redirect alla pagina principale
        this.router.navigate([data.session.url_home]);
      }, 
      error => {
        console.log(error);
      }
    );
  }

  logout(){
    let body = {
      "log.out" : 1
    };

    this.api
    .post('php/api/login.api.php' , body)
    .subscribe(data => {
        let result = data.result;
        if(result){
          this.session.get();
          
          this.router.navigate(['login']);
        } else {
          alert(`Error: ${result}`);
        }
      }, 
      error => {
        console.log(`Error: ${error}`);
      }
    );
  }

}


