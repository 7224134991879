import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteGuardService } from "./services/route-guard.service";

import { DashboardComponent } from './1/dashboard/dashboard.component';
import { RegistrazioneComponent } from './registrazione/registrazione.component';
import { LoginComponent } from './login/login.component';
import { UtentiComponent } from './1/utenti/utenti.component';
import { ConfigsComponent } from './1/configs/configs.component';
import { TestComponent } from './1/test/test.component';
import { ImmobiliComponent } from './1/immobili/immobili.component';


const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'registrazione/:id_ufficio', component: RegistrazioneComponent},
  { path: '1/dashboard', component: DashboardComponent , canActivate: [RouteGuardService]},
  { path: '1/utenti', component: UtentiComponent , canActivate: [RouteGuardService]},
  { path: '1/immobili', component: ImmobiliComponent , canActivate: [RouteGuardService]},
  { path: '1/configs', component: ConfigsComponent , canActivate: [RouteGuardService]},
  { path: '1/test', component: TestComponent  , canActivate: [RouteGuardService]}
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}