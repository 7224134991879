<div class="navbar navbar-expand-sm fixed-top navbar-light bg-light">
  <div class="container-fluid">
    <button class="navbar-toggler btn-sm" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarToggler">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" href="/1/dashboard">
            <i class="fas fa-globe mr-3 c-6" matTooltip="LOGO"></i>
          </a>
        </li>
        <li class="nav-item {{(router.url == '/1/dashboard') ? 'navItemActive' : ''}}">
          <a class="nav-link" href="/1/dashboard" *ngIf="session.id_permesso == 1" routerLink="/1/dashboard">
            <!--i class="fas fa-home mr-3"></i-->
            <!--span class="d-none d-lg-block ml-3"-->Home<!--/span-->
          </a>
        </li>
        <li class="nav-item {{(router.url == '/1/utenti') ? 'navItemActive' : ''}}">
          <a class="nav-link" href="/1/utenti" *ngIf="session.id_permesso == 1" routerLink="/1/utenti">
            <!--i class="fas fa-users mr-3"></i-->
            <!--span class="d-none d-lg-block ml-3"-->Utenti<!--/span-->
          </a>
        </li>
        <li class="nav-item {{(router.url == '/1/immobili') ? 'navItemActive' : ''}}">
          <a class="nav-link" href="/1/immobili" *ngIf="session.id_permesso == 1" routerLink="/1/immobili">
            <!--i class="fas fa-building mr-3"></i-->
            <!--span class="d-none d-lg-block ml-3"-->Immobili<!--/span-->
          </a>
        </li>
        <li class="nav-item {{(router.url == '/1/configs') ? 'navItemActive' : ''}}">
          <a class="nav-link"  href="/1/configs" *ngIf="session.id_permesso == 1" routerLink="/1/configs">
            <i class="fas fa-cog mr-3" matTooltip="CONFIGURAZIONI"></i>
            <!--span class="d-none d-lg-block ml-3">Config</span-->
          </a>
        </li>
        <li class="nav-item {{(router.url == '/login') ? 'navItemActive' : ''}}">
          <a class="nav-link" href="/1/login" *ngIf="session.is_logged" routerLink="/login">
            <i class="fas fa-power-off mr-3" matTooltip="ESCI"></i>
            <!--span class="d-none d-lg-block ml-3">Esci</span-->
          </a>
        </li>
      </ul>
    </div><!--collapse-->
  </div><!--div-fluid-->
</div><!--navbar-->
