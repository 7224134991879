import { Component, Input, OnInit, ViewChild} from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { SessionService } from '../services/session.service';
import { MatSidenav} from '@angular/material/sidenav';
import { Router } from '@angular/router';

const SMALL_WIDTH_BK = 720;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  
  @ViewChild('drawer') drawer: MatSidenav;

 
  public isScreenSmall:boolean;

  constructor(
    public breakpoint: BreakpointObserver,
    public session: SessionService,
    private router: Router
  ){}

  ngOnInit(): void{
    //se il device è piccolo nascondi la sidebar
    this.breakpoint
    //.observe([Breakpoints.XSmall , Breakpoints.Small])
    .observe([`(max-width: ${SMALL_WIDTH_BK}px)`])
    .subscribe((state: BreakpointState) => {
      this.isScreenSmall = state.matches;
    });

    //occulta la sidebar al click solo su devices piccoli
    this.router.events.subscribe(() => {
      if(this.drawer && this.isScreenSmall){
        //fare contollo su drawer opened
        //non usare @viewchild ma usare variabile pubblica
        //per evitare errori al refresh in visualizzazione smartphone
        this.drawer.close();
      }
    });
  }
  
  toggle(){
    //usato decorator al posto di utilizzare funzione direttamente nella view
    //perchè quando non si è loggati *ngIf nasconde il drawer causando 
    //errore js in consolle
    this.drawer.toggle();
  }

}
