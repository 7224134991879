<table class="table table-borderless table-hover pointer">
    <thead class="table-light">
      <tr>
        <td>#</td>
        <td>Attività</td>
        <td>Provider</td>
        <td><span class="fas fa-cog"></span></td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let x of data; let i = index">
        <td><span class="badge bg-light text-dark">{{x.id_pratica}}</span></td>
        <td>{{x.attivita}}</td>
        <td>{{x.provider}}</td>
        <td><!--cog--></td>
      </tr>
    </tbody>
    <tfoot class="table-light">
      <tr>
        <td><!--id_pratica--></td>
        <td>
          <select class="form-select form-select-sm" [(ngModel)]="new.id_attivita">
              <option *ngFor="let x of attivita" value="{{x.id}}">{{x.value}}</option>
          </select>
        </td>
        <td>
          <select class="form-select form-select-sm" [(ngModel)]="new.id_provider">
            <option *ngFor="let x of providers" value="{{x.id}}">{{x.value}}</option>
        </select>
        </td>  
        <td>
          <button type="button" 
            class="btn btn-outline-success btn-sm" 
            [disabled]="new.id_attivita==0 || new.id_provider==0"
            (click)="insert()">
            <span class="fas fa-plus"></span>
          </button>
        </td>
      </tr>
    </tfoot>
</table>